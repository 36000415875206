
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { SessionProvider } from "next-auth/react";
import Head from "next/head";
import { ApolloClient, ApolloProvider, InMemoryCache, from, } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";
import "tailwindcss/tailwind.css";
import "@/assets/fonts/fonts.css";
import "@/assets/icons/iconsax-css/style.css";
import "react-datepicker/dist/react-datepicker.css";
import "./global.css";
import { logOffInactivityMinutesVar, goBackVar } from "@/utils/variables";
export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                logOffInactivityMinutes: {
                    read() {
                        return logOffInactivityMinutesVar();
                    }
                },
                goBack: {
                    read() {
                        return goBackVar();
                    }
                }
            }
        }
    }
});
// Error handling link
const errorLink = onError(({ graphQLErrors, networkError }) => {
    const currentPage = window?.location?.href;
    if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
            console.log(`[GraphQL error](page:"${currentPage}"): ${JSON.stringify(error)}`);
            const statusCode = Number(error?.extensions?.code);
            if (401 <= statusCode && statusCode < 500) {
                // Redirect to the 403 page for any 4XX errors
                if (typeof window !== "undefined") {
                    window.location.href = "/403";
                }
            }
        });
    }
    if (networkError)
        console.log(`[Network error]: ${networkError}`);
});
// HTTP link
const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_BASE_URL + "/api/graphql"
});
export const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    cache
});
function MyApp({ Component, pageProps: { session, ...pageProps } }) {
    return (<>
      <Head>
        <title>EPPI</title>
        <link rel="icon" href="/favicon.ico"/>
      </Head>
      <SessionProvider session={session} basePath="/api/auth">
        <ApolloProvider client={client}>
          <Component {...pageProps}/>
        </ApolloProvider>
      </SessionProvider>
    </>);
}
const __Next_Translate__Page__193b7a9e65d__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__193b7a9e65d__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  